<template>
	<div id="app">
		<NavigationBar />
		<component v-bind:is="this.$root.currentView"></component>
	</div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import BooksView from './views/BooksView.vue'
import ReportView from './views/ReportView.vue'
import OrdersView from './views/OrdersView.vue'
import AdminListView from './views/AdminListView.vue'
import AdminOrderView from './views/AdminOrderView.vue'
import ProfileView from './views/ProfileView.vue'
import InfoView from './views/InfoView.vue'
import LoginView from './views/LoginView.vue'
export default {
	name: 'App',
	components: {
		NavigationBar,
		BooksView,
		ReportView,
		OrdersView,
		AdminListView,
		AdminOrderView,
		ProfileView,
		InfoView,
		LoginView,
	},
}
</script>

<style>
#app {
	height: 100%;
	color: #000;
	background-color: hsl(0, 0%, 25%);
	display: flex;
	flex-direction: column;
	/* style | variant | weight | size/line-height | family */
	/* font: normal normal 400 1em/1.45 'Fira Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; */
}
</style>
