<template>
	<div class="BooksView view">
		<div class="title" v-if="multiplesBibliotecas" v-text="title"></div>
		<div class="section first" v-show="pBooks.length > 0">Libros</div>
		<div class="listado">
			<BookCard v-for="book in pBooks" :key="book.numero" :book="book" />
		</div>
		<div class="section" v-show="eBooks.length > 0">e-Books</div>
		<div class="listado">
			<BookCard v-for="book in eBooks" :key="book.numero" :book="book" />
		</div>
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, onChildAdded, query, orderByChild } from "firebase/database"
import BookCard from '../components/BookCard.vue'

export default {
	name: 'BooksView',
	components: {
		BookCard
	},
	data: function () {
		return {
			books: [],
			selectedBook: {},
		}
	},
	computed: {
		title: function () {
			return this.$root.currentBiblioteca.titulo
		},
		multiplesBibliotecas: function () {
			return this.$root.yo && Object.keys(this.$root.yo.bibliotecas).length > 1
		},
		pBooks: function () {
			return this.books.filter(book => !book.ebook)
		},
		eBooks: function () {
			return this.books.filter(book => book.ebook)
		},
	},
	created: function () {
		this.getBooks()
	},
	ready: function () {
		// $('.ui.dropdown').dropdown()
		// console.log(this.books.length)
	},
	methods: {
		getBooks: function () {
			let that = this
			const loading = setInterval(function () {
				//console.log('vuelta')
				if (that.$root.idUserBibliotecaActual !== undefined && that.$root.idUserBibliotecaActual !== null) {
					that.getBooks2()
					clearInterval(loading);
				}
			}, 100); // Checks every 100ms(0.1s)
		},
		getBooks2: function () {
			this.books = []
			const biblioteca = this.$root.idUserBibliotecaActual
			const booksRef = query(ref(db, `bibliotecas/${biblioteca}/libros`), orderByChild('numero'))
			let that = this
			onChildAdded(booksRef, function (snap) {
				let book = snap.val()
				if (!book.agotado && !book.oculto) {
					book.isbn = snap.key
					that.books.push(book)
				}
			})

		}
	}
}
</script>

<style scoped>
.BooksView {
	padding-bottom: 2rem;
	overflow-y: scroll;
}

.title {
	font-size: 3.6em;
	line-height: 1em;
	color: hsla(0, 0%, 45%, 1);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding-top: 0.5rem;
}

.section {
	font-size: 3em;
	line-height: 1em;
	color: hsl(25, 75%, 46%);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding-top: 4px;
	margin: 2rem 0;
	background: linear-gradient(90deg, hsla(0, 0%, 25%, 0.05) 5%, hsla(0, 0%, 32%, 1) 30%, hsla(0, 0%, 32%, 1) 70%, hsla(0, 0%, 25%, 0.05) 95%);
}

.section.first {
	/* background: inherit; */
	margin: 1.6rem 0 0.9rem;
}

.listado {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0.5em;
	gap: 15px;
}
</style>