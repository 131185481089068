<template>
	<div class="order-admin">
		<!-- Historial de estados -->
		<table class="estados ui very compact celled table">
			<thead>
				<tr>
					<th>Pedido <a class="ui black circular large label">{{ pedido.numero }}</a></th>
					<th>Fecha</th>
					<th>Referencia</th>
					<th>Operador</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="record in historyByDateDesc" :key="record.fecha">
					<td>
						<OrdersStatusWidget :value="record.estado" :label="true"></OrdersStatusWidget>
					</td>
					<td>{{ record.fecha | longTimestamp }}</td>
					<td>{{ record.referencia }}</td>
					<td>{{ record.operador }}</td>
				</tr>
				<tr>
					<td>
						<div><i class="ui red mail icon" style="font-size: 1.2em"></i> Nuevo</div>
					</td>
					<td>{{ pedido.pedido_en | longTimestamp }}</td>
					<td></td>
					<td>Delegado</td>
				</tr>
			</tbody>
			<tfoot class="full-width">
				<tr>
					<th colspan="4">
						<button class="ui compact right labeled icon small blue button" @click.prevent="showStatusManager">
							<i class="add icon"></i> Estado </button>
					</th>
				</tr>
			</tfoot>
		</table>
		<!-- Datos del libro -->
		<table class="ui very compact fixed definition table">
			<thead class="full-width">
				<tr>
					<th class="three wide"></th>
					<th class="seccion"><i class="ui book large icon"></i> Libro</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Número</td>
					<td>{{ pedido.libro.numero }} ({{ pedido.biblioteca }})</td>
				</tr>
				<tr>
					<td>ISBN</td>
					<td>{{ pedido.libro.isbn }}</td>
				</tr>
				<tr>
					<td>Título</td>
					<td>{{ pedido.libro.titulo }}</td>
				</tr>
				<tr>
					<td>Autores</td>
					<td>{{ pedido.libro.autores }}</td>
				</tr>
				<tr>
					<td>Precio</td>
					<td>{{ pedido.libro.precio | formatEU }} €</td>
				</tr>
			</tbody>
		</table>
		<!-- Datos del delegado -->
		<table class="ui very compact fixed definition table">
			<thead class="full-width">
				<tr>
					<th class="three wide"><span v-show="pedido.referencias.enviar == 'delegado'">ENVIAR</span></th>
					<th class="seccion"><i class="ui user large icon"></i> Delegado</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="three wide">Nombre</td>
					<td>{{ pedido.pedido_por.nombre }}</td>
				</tr>
				<tr>
					<td>Apellidos</td>
					<td>{{ pedido.pedido_por.apellidos }}</td>
				</tr>
				<tr>
					<td>Email</td>
					<td>{{ pedido.pedido_por.email }}</td>
				</tr>
				<tr>
					<td>Telefono</td>
					<td>{{ pedido.pedido_por.telefono }}</td>
				</tr>
				<tr>
					<td>Dirección</td>
					<td>{{ pedido.pedido_por.direccion }}</td>
				</tr>
				<tr>
					<td>Cod. Postal</td>
					<td>{{ pedido.pedido_por.codigo_postal }}</td>
				</tr>
				<tr>
					<td>Población</td>
					<td>{{ pedido.pedido_por.poblacion }}</td>
				</tr>
				<tr>
					<td>Provincia</td>
					<td>{{ pedido.pedido_por.provincia }}</td>
				</tr>
			</tbody>
		</table>
		<!-- Datos del médico -->
		<table class="ui very compact fixed definition table">
			<thead class="full-width">
				<tr>
					<th class="three wide"><span v-show="pedido.referencias.enviar == 'medico'">ENVIAR</span></th>
					<th class="seccion"><i class="ui doctor large icon"></i> Médico</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="three wide">Nombre</td>
					<td>{{ pedido.referencias.nombre }}</td>
				</tr>
				<tr>
					<td>Apellidos</td>
					<td>{{ pedido.referencias.apellidos }}</td>
				</tr>
				<tr>
					<td>Centro</td>
					<td>{{ pedido.referencias.centro }}</td>
				</tr>
				<tr>
					<td>Especialidad</td>
					<td>{{ pedido.referencias.especialidad }}</td>
				</tr>
				<tr>
					<td>Teléfono</td>
					<td>{{ pedido.referencias.telefono }}</td>
				</tr>
				<tr>
					<td>Email</td>
					<td>{{ pedido.referencias.email }}</td>
				</tr>
				<tr>
					<td>Dirección</td>
					<td>{{ pedido.referencias.direccion }}</td>
				</tr>
				<tr>
					<td>Cód. Postal</td>
					<td>{{ pedido.referencias.cpostal }}</td>
				</tr>
				<tr>
					<td>Población</td>
					<td>{{ pedido.referencias.poblacion }}</td>
				</tr>
				<tr>
					<td>Provincia</td>
					<td>{{ pedido.referencias.provincia }}</td>
				</tr>
			</tbody>
		</table>
		<!-- Status manager -->
		<div class="status-manager ui small modal">
			<div class="header"> Añadir nuevo estado </div>
			<div class="content">
				<select class="ui dropdown" v-model="newStatus">
					<option value="">Selecciona Estado</option>
					<option value="en_proceso"><i class="ui orange settings icon" style="font-size: 1.2em"></i>En Proceso</option>
					<option value="encargado"><i class="ui blue calendar icon" style="font-size: 1.2em"></i>Encargado</option>
					<option value="facturado"><i class="ui green check square icon" style="font-size: 1.2em"></i>Facturado</option>
					<option value="anulado"><i class="ui grey ban icon" style="font-size: 1.2em"></i>Anulado</option>
				</select>
				<textarea rows="3" placeholder="Referencia" v-model="reference"></textarea>
			</div>
			<div class="actions">
				<div class="ui black large button" @click.prevent="hideStatusManager">Cancelar</div>
				<div class="ui green large button" @click.prevent="addNewStatus">Guardar</div>
			</div>
		</div>
	</div>
</template>

<script>
/* global  $ */
// Necesaria para jQuery

import { db } from '../firebaseInit.js'
import { ref, query, onChildAdded, serverTimestamp, runTransaction, update, push, set } from "firebase/database"
import OrdersStatusWidget from '../components/OrdersStatusWidget.vue'
import { Decimal } from 'decimal.js'

export default {
	name: 'AdminOrderView',
	components: {
		OrdersStatusWidget
	},
	data: function () {
		return {
			newStatus: null,
			reference: null,
			historia: [],
		}
	},
	computed: {
		pedido: function () {
			return this.$root.orderDetail
		},
		historyByDateDesc: function () {
			const historyCopy = [...this.historia]
			return historyCopy.sort((a, b) => (a.fecha > b.fecha) ? -1 : 1)
		}
	},
	created: function () {
		this.setListenerOnHistoria()
	},
	methods: {
		setListenerOnHistoria: function () {
			var that = this
			const dataRef = query(ref(db, 'pedidos/' + this.pedido.numero + '/historia'))
			onChildAdded(dataRef, function (snap) {
				that.historia.push(snap.val())
			})
		},
		showStatusManager: function () {
			$('.status-manager.ui.modal').modal('setting', 'context', '.order-admin').modal('setting', 'autofocus', false).modal('setting', 'closable', true).modal('setting', 'detachable', false).modal('setting', 'allowMultiple', false).modal('setting', 'transition', 'fade down').modal('setting', 'duration', 1200).modal('show')
			this.$nextTick(function () {
				$('.status-manager .ui.dropdown').dropdown()
			})
		},
		hideStatusManager: function () {
			this.newStatus = null
			this.reference = null
			$('.status-manager.ui.modal').modal('hide')
		},
		addNewStatus: async function () {
			var nroPedido = this.pedido.numero
			var biblioteca = this.pedido.biblioteca
			var uidDelegado = this.pedido.pedido_por.uid
			var importe = this.pedido.libro.precio
			var status = {
				operador: this.$root.yo.nombre + ' ' + this.$root.yo.apellidos[0] + '.',
				fecha: serverTimestamp(),
				estado: this.newStatus,
				referencia: this.reference,
			}
			if (status.estado == 'anulado') {

				const transactionResult = await runTransaction(ref(db, 'usuarios/' + uidDelegado + '/bibliotecas/' + biblioteca), (objBib) => {
					objBib.pedidos = (objBib.pedidos || 0) - 1
					objBib.consumido = Decimal(objBib.consumido).minus(importe).toNumber()
					return objBib
				})

				if (!transactionResult.committed) {
					//TODO: log error No se abonó el importe!
					return
				}

				const newStatusRef = push(ref(db, 'pedidos/' + nroPedido + '/historia')) // Obtiene un UID
				set(newStatusRef, status) // añade el estado a la historia

				const updates = {
					['pedidos/' + nroPedido + '/libro/precio']: 0, // pone importe del pedido a cero
					['pedidos/' + nroPedido + '/estado_actual']: this.newStatus, // cambia el estado actual
				};
				update(ref(db), updates)

				this.hideStatusManager() // Cierra el modal

			} else {

				const newStatusRef = push(ref(db, 'pedidos/' + nroPedido + '/historia')) // Obtiene un UID
				set(newStatusRef, status) // añade el estado a la historia

				const updates = {
					['pedidos/' + nroPedido + '/estado_actual']: this.newStatus, // cambia el estado actual
				};
				update(ref(db), updates)

				this.hideStatusManager() // Cierra el modal

			}
		},
	},
}
</script>

<style scoped>
.order-admin {
	flex: 1 1 auto;
	width: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em 0.5em 3em;
}

.ui.table {
	margin: 0 auto 0.8em;
	width: 100%;
	max-width: 60rem;
	color: black;
}

.estados.ui.table thead th {
	background: hsl(0, 0%, 80%);
	padding-top: 0.6em;
	padding-bottom: 0.4em;
	font-weight: 400;
	color: black;
}

.ui.table thead th {
	background: hsl(0, 0%, 95%);
}

:not(.estados).ui.table th:first-of-type {
	color: hsl(134, 70%, 50%);
	text-align: right;
	padding: 5px 4px 0;
}

.ui.table thead th.seccion {
	padding-top: 0.6em;
	padding-bottom: 0.2em;
	color: hsla(25, 85%, 37%, 1);
}

.ui.definition.table tr td:first-child {
	color: hsl(0, 0%, 56%);
	font-size: 0.9em;
}

.seccion i {
	line-height: 0.8;
}

.ui.definition.table tr td:first-child {
	color: hsl(0, 0%, 56%);
	font-size: 0.9em;
}

.modal textarea {
	display: block;
	width: 100%;
	margin-top: 1em;
}
</style>