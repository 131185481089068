import { render, staticRenderFns } from "./ReportRegionCounters.vue?vue&type=template&id=662bcd4e&scoped=true&"
import script from "./ReportRegionCounters.vue?vue&type=script&lang=js&"
export * from "./ReportRegionCounters.vue?vue&type=script&lang=js&"
import style0 from "./ReportRegionCounters.vue?vue&type=style&index=0&id=662bcd4e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662bcd4e",
  null
  
)

export default component.exports