<template>
	<Table>
		<thead>
			<tr>
				<th>Regiones</th>
				<th>Pedidos</th>
				<th>Asignado</th>
				<th>Consumido</th>
				<th>Disponible</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="usuario in usuarios" :key="usuario.uid" v-on:click="goToDelegadoOrders(usuario.rol, usuario.uid)" v-bind:class="{ isDelegado: usuario.rol === 'delegado' }">
				<td v-text="usuario.nombre + ' ' + usuario.apellidos"></td>
				<td v-text="usuario.pedidos"></td>
				<td>{{ usuario.asignado | formatEU }}</td>
				<td>{{ usuario.consumido | formatEU }}</td>
				<td>{{ usuario.asignado - usuario.consumido | formatEU }}</td>
			</tr>
		</tbody>
	</Table>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, query, equalTo, orderByChild, onChildAdded } from "firebase/database"

export default {
	name: 'ReportRegionTable',
	props: ['jefeUid'],
	data: function () {
		return {
			usuarios: [],
		}
	},
	created: function () {
		if (this.jefeUid) {
			this.getDelegados(this.jefeUid)
		} else {
			this.getJefes()
		}
	},
	methods: {
		getDelegados: function (jefeUid) {
			const idBiblioteca = this.$root.yo.preferencias.biblioteca_actual
			// obtiene datos de los delegados cuyo superior es este jefe
			const dataRef = query(ref(db, 'usuarios'), orderByChild('bibliotecas/' + idBiblioteca + '/superior'), equalTo(jefeUid))
			const that = this
			onChildAdded(dataRef, function (snap) {
				const delegado = snap.val()
				const dataDelegado = {}
				dataDelegado.uid = snap.key
				dataDelegado.nombre = delegado.nombre
				dataDelegado.apellidos = delegado.apellidos
				dataDelegado.asignado = delegado.bibliotecas[idBiblioteca].asignado
				dataDelegado.consumido = delegado.bibliotecas[idBiblioteca].consumido
				dataDelegado.pedidos = delegado.bibliotecas[idBiblioteca].pedidos
				dataDelegado.rol = delegado.rol
				that.usuarios.push(dataDelegado)
			})

		},
		getJefes: function () {
			// Si es un Jefe, sólo se pone el mismo
			if ('jefe' == this.$root.yo.rol) this.jefes.push(this.$root.yo)
			// Si es un admin, pone todos los jefes de esta biblioteca
			if ('admin' == this.$root.yo.rol || 'axon_admin' == this.$root.yo.rol || 'axon_operador' == this.$root.yo.rol) {
				const idBiblioteca = this.$root.yo.preferencias.biblioteca_actual
				// console.log('getJefes >', idBiblioteca)
				const dataRef = query(ref(db, 'usuarios'), equalTo('jefe'), orderByChild('rol'))
				const that = this
				onChildAdded(dataRef, function (snap) {
					// Obtiene datos de los jefes que entre sus bibliotecas tienen la actual
					if (Object.prototype.hasOwnProperty.call(snap.val().bibliotecas, idBiblioteca)) {
						const dataJefe = {
							asignado: 0,
							consumido: 0,
							pedidos: 0
						}
						const jefe = snap.val()
						dataJefe.uid = snap.key
						dataJefe.nombre = jefe.nombre
						dataJefe.apellidos = jefe.apellidos
						dataJefe.rol = jefe.rol
						that.usuarios.push(dataJefe)

						// obtiene datos de los delegados cuyo superior es este jefe
						const dataRef = query(ref(db, 'usuarios'), orderByChild('bibliotecas/' + idBiblioteca + '/superior'), equalTo(dataJefe.uid))
						onChildAdded(dataRef, function (snap) {
							const countersDelegado = snap.val().bibliotecas[idBiblioteca]
							const jefe = that.usuarios.find(x => x.uid === dataJefe.uid)
							jefe.asignado += countersDelegado.asignado
							jefe.consumido += countersDelegado.consumido
							jefe.pedidos += countersDelegado.pedidos
						})
					}
				})
			}
		},
		goToDelegadoOrders: function (rol, uid) {
			if (rol === 'delegado') {
				this.$root.reportSelectedUser = uid
				this.$root.route('OrdersView')
			}
		}
	}
}
</script>

<style scoped>
table {
	border-spacing: 1;
	border-collapse: collapse;
	background: #555;
	color: white;
	border-radius: 6px;
	overflow: hidden;
	max-width: 564px;
	width: 100%;
	margin: 1.5rem auto 0.5rem;
}

thead tr {
	height: 50px;
	background: hsl(215, 24%, 22%);
}

th {
	font-weight: 400;
}

tbody tr {
	height: 44px;
	border-bottom: 1px solid #d0d0d0;
}

tbody tr:last-child {
	border: 0;
}

th,
td {
	text-align: right;
	padding: 0 8px
}

.isDelegado {
	cursor: pointer;
}
</style>