<template>
	<div class="view">
		<div class="info">
			<a href="http://www.axon.es" target="_blank">
				<img src="img/axon_256x128.gif" alt="logotipo">
			</a>
			<p><i class="mail icon"></i> axon@axon.es</p>
			<p><i class="phone icon"></i><small>915 939 999</small></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InfoView',
}
</script>

<style scoped>
.info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
	/*background-color: WhiteSmoke;*/
}

.info>p {
	font-size: 1.6em;
	margin: 0.8em 0 0;
	color: hsla(0, 100%, 100%, 0.6);
}

.info>p>small {
	font-size: 88%;
	position: relative;
	top: -3px;
}
</style>