<template>
	<div class="profile view">
		<div class="ui form">
			<div class="ten wide field">
				<label>E-mail</label>
				<input v-model="profile.email" type="text" readonly="">
			</div>
			<div class="fields">
				<div class="six wide field" :class="{ 'error': !valid.nombre }">
					<label>Nombre</label>
					<input autofocus v-model="profile.nombre" type="text" placeholder="">
				</div>
				<div class="ten wide field" :class="{ 'error': !valid.apellidos }">
					<label>Apellidos</label>
					<input name="last-name" v-model="profile.apellidos" type="text" placeholder="">
				</div>
			</div>
			<div class="field" :class="{ 'error': !valid.telefono }">
				<label>Teléfono móvil o fijo</label>
				<input v-model="profile.telefono" type="text" placeholder="Si es móvil, Notificaremos los envíos por SMS">
			</div>
			<div class="field" :class="{ 'error': !valid.direccion }">
				<label>Dirección</label>
				<input name="address" v-model="profile.direccion" type="text" placeholder="Calle, número, piso">
			</div>
			<div class="fields">
				<div class="three wide field" :class="{ 'error': !valid.zip }">
					<label>Cód. Postal</label>
					<input name="zip" v-model="profile.codigo_postal" type="text" placeholder="5 dígitos">
				</div>
				<div class="thirteen wide field" :class="{ 'error': !valid.poblacion }">
					<label>Población</label>
					<input name="citi" v-model="profile.poblacion" type="text" placeholder="Ciudad">
				</div>
			</div>
			<div style="margin-bottom: .34em;" class="field" :class="{ 'error': !valid.provincia }">
				<label>Provincia</label>
				<select class="ui dropdown" v-model="profile.provincia">
					<option value="">Provincia</option>
					<option v-for="provincia in provincias" :key="provincia" v-bind:value="provincia"> {{ provincia }} </option>
				</select>
			</div>
			<!-- <div v-if="implementar" class="field">
				<label>Biblioteca</label>
				<div class="ui selection dropdown">
					<input type="hidden" name="biblioteca" v-model="profile.biblioteca">
					<i class="dropdown icon"></i>
					<div class="default text">Escoger Biblioteca</div>
					<div class="menu">
						<div class="item" data-value="locomotor">Aparato Locomotor</div>
						<div class="item" data-value="oftalmologia">Oftalmología</div>
					</div>
				</div>
			</div> -->
			<div class="ui left labeled button right floated" v-if="isValid" @click.stop.prevent="guardar" tabindex="0">
				<a class="ui basic green label">Guardar datos y continuar </a>
				<div class="ui icon green large button"><i class="checkmark icon"></i></div>
			</div>
			<div class="ui left labeled button right floated" v-if="!isValid" @click.stop.prevent="" tabindex="0">
				<a class="ui basic black label">Complete todos los campos </a>
				<div class="ui icon black large button"><i class="minus circle icon"></i></div>
			</div>
		</div>
	</div>
</template>

<script>
/* global  $ */
// Necesaria para jQuery

import { db } from '../firebaseInit.js'
import { ref, update, serverTimestamp, push, set } from "firebase/database"

export default {
	name: 'ProfileView',
	data: function () {
		return {
			profile: {},
			provincias: [
				'Álava',
				'Albacete',
				'Alicante',
				'Almería',
				'Ávila',
				'Badajoz',
				'Baleares',
				'Barcelona',
				'Burgos',
				'Cáceres',
				'Cádiz',
				'Castellón',
				'Ciudad Real',
				'Córdoba',
				'La Coruña',
				'Cuenca',
				'Gerona',
				'Granada',
				'Guadalajara',
				'Guipuzcoa',
				'Huelva',
				'Huesca',
				'Jaén',
				'León',
				'Lérida',
				'La Rioja',
				'Lugo',
				'Madrid',
				'Málaga',
				'Murcia',
				'Navarra',
				'Orense',
				'Asturias',
				'Palencia',
				'Las Palmas',
				'Pontevedra',
				'Salamanca',
				'Santa Cruz de Tenerife',
				'Cantabria',
				'Segovia',
				'Sevilla',
				'Soria',
				'Tarragona',
				'Teruel',
				'Toledo',
				'Valencia',
				'Valladolid',
				'Vizcaya',
				'Zamora',
				'Zaragoza',
				'Ceuta',
				'Melilla',
			],
		}
	},
	mounted: function () {
		$('.ui.dropdown').dropdown()
	},
	created: function () {
		var yo = this.$root.yo
		this.profile = {
			email: yo.email,
			nombre: yo.nombre,
			apellidos: yo.apellidos,
			telefono: yo.telefono,
			direccion: yo.direccion,
			codigo_postal: yo.codigo_postal,
			perfil_completo: yo.perfil_completo,
			poblacion: yo.poblacion,
			provincia: yo.provincia,
		}
	},
	computed: {
		valid: function () {
			var p = this.profile
			return {
				nombre: p.nombre && p.nombre.trim().length >= 3,
				apellidos: p.apellidos && p.apellidos.trim().length >= 3,
				telefono: p.telefono && p.telefono.trim().length >= 3,
				direccion: p.direccion && p.direccion.trim().length >= 3,
				zip: p.codigo_postal && /^\d{5}$/.test(p.codigo_postal),
				poblacion: p.poblacion && p.poblacion.trim().length >= 3,
				provincia: p.provincia && p.provincia.trim().length >= 3,
			}
		},
		isValid: function () {
			var valid = this.valid
			return Object.keys(valid).every(function (key) {
				return valid[key]
			})
		},
	},
	methods: {
		guardar: function () {
			delete this.profile.email // el email se actualiza en un proceso aparte
			this.profile.perfil_completo = true // si el formulario es válido, marcamos el perfil como completo.

			const updates = {
				['nombre']: this.profile.nombre,
				['apellidos']: this.profile.apellidos,
				['telefono']: this.profile.telefono,
				['direccion']: this.profile.direccion,
				['codigo_postal']: this.profile.codigo_postal,
				['perfil_completo']: this.profile.perfil_completo,
				['poblacion']: this.profile.poblacion,
				['provincia']: this.profile.provincia,
			}

			update(ref(db, `usuarios/${this.$root.yo.uid}`), updates)
				.then(() => this.$root.route('BooksView'))
				.catch((error) => {
					this.logUserError('Error al guardar datos del usuario ' + this.$root.yo.uid, error)
					this.$root.route('BooksView')
				})

		},
		logUserError(message, error) {
			const data = {
				text: message,
				error: error || null,
				en: serverTimestamp()
			}
			const newErrorRef = push(ref(db, 'errores/User'))
			set(newErrorRef, data)
		},

	},
}
</script>

<style scoped>
.form {
	width: 100%;
	max-width: 40em;
	margin: auto;
	padding: 2em;
	background-color: steelblue;
}

.ui.form .field>label {
	font-weight: 500;
}

.labeled.button {
	margin: 1.3em 0;
}
</style>