// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyACJJvk79szbajnN_2I7WpzkiZuoYCz940',
	authDomain: 'bib-pfizer-privados.firebaseapp.com',
	databaseURL: 'https://bib-pfizer-privados-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'bib-pfizer-privados',
	storageBucket: 'bib-pfizer-privados.appspot.com',
	messagingSenderId: '1061234106872',
	appId: '1:1061234106872:web:ecabe16c4231b2b9af760d',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

//initialize firebase auth
const auth = getAuth()

// Initialize Realtime Database and get a reference to the service
const db = getDatabase(app)

export { app, auth, db }
