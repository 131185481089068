<template>
	<div class="view">
		<div class="orders-list">
			<div class="title" v-text="$root.currentBiblioteca.titulo"></div>
			<div class="header">
				<div class="name" v-text="user.nombre + ' ' + user.apellidos"></div>
				<div class="stats">
					<div class="ui labeled button small" tabindex="0">
						<div class="ui grey button small">
							<i v-if="isAdmin" class="minus square icon" @click.prevent="showBudgetManager('minus')"></i> Presupuesto <i v-if="isAdmin" class="add square icon" @click.prevent="showBudgetManager('add')"></i>
						</div>
						<a class="ui basic left pointing grey label small"> {{ asignado | formatEU }} € </a>
					</div>
					<div class="ui labeled button small" tabindex="0">
						<div class="ui purple button small"> {{ nroPedidos | formatEU }} Pedidos </div>
						<a class="ui basic left pointing purple label small"> {{ consumido | formatEU }} € </a>
					</div>
					<div class="ui labeled button small" tabindex="0">
						<div class="ui violet button small"> Disponible </div>
						<a class="ui basic left pointing violet label small"> {{ asignado - consumido | formatEU }} € </a>
					</div>
				</div>
			</div>
			<div class="order" :class="{ 'anulado': pedido.estado_actual == 'anulado' }" v-for="pedido in pedidos" :key="pedido.numero" :pedido="pedido">
				<a class="ui grey ribbon label">{{ pedido.pedido_en | timestampPedidos }}</a>
				<OrdersStatusWidget :value="pedido.estado_actual || 'nuevo'" :size="1.4" :label="true"></OrdersStatusWidget>
				<ul>
					<li><label>EUR </label>{{ pedido.libro.precio | formatEU }}</li>
					<li><label>pedido </label>{{ pedido.numero }}</li>
				</ul>
				<div>{{ pedido.libro.titulo }}</div>
				<div>{{ pedido.referencias.nombre }} {{ pedido.referencias.apellidos }} | {{ pedido.referencias.centro }} | {{ pedido.referencias.especialidad }} | {{ pedido.referencias.email }} | {{ pedido.referencias.poblacion }} | {{ pedido.referencias.provincia }}</div>
			</div>
			<!-- BudgetManager -->
			<div class="budget-manager ui small modal">
				<div class="header" v-text="user.nombre + ' ' + user.apellidos">
				</div>
				<div class="content">
					<!--tabla Biblioteca-->
					<table class="ui very compact unstackable definition table">
						<thead class="full-width center aligned">
							<tr>
								<th :class="budget.sign" colspan="2">
									<i class="ui university large icon"></i> Biblioteca <i :class="budget.sign == 'minus' ? 'plus' : 'minus'" class="ui large icon"></i>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="ten wide">Dotación</td>
								<td class="right aligned">{{ calculator.dotacion }}</td>
							</tr>
							<tr>
								<td>Repartido</td>
								<!-- <td class="right aligned">{{ calculator.repartido | accountingES }}</td> -->
								<td class="right aligned">{{ calculator.repartido }}</td>
							</tr>
							<tr>
								<td>Libre</td>
								<td class="right aligned">{{ calculator.libre }}</td>
							</tr>
							<tr :class="budget.sign" class="right aligned">
								<td>
									<span v-if="budget.sign == 'add'">Ampliar presupuesto delegado</span>
									<span v-else>Reducir presupuesto delegado</span>
								</td>
								<td style="padding:0">
									<div class="ui fluid left icon input">
										<i v-if="budget.sign == 'add'" class="ui arrow down green large icon"></i>
										<i v-else class="ui arrow up red large icon"></i>
										<!-- <input type="text" v-model="budget.amount | accountingES" number pattern="\d+|\d+,|\d+,\d{1,2}|,\d{1,2}" @input=""> -->
										<input type="text" v-model="budget.amount" number pattern="\d+|\d+,|\d+,\d{1,2}|,\d{1,2}">
									</div>
								</td>
							</tr>
							<tr>
								<td>Presupuesto</td>
								<td class="right aligned">{{ calculator.presupuesto }}</td>
							</tr>
							<tr>
								<td>Disponible</td>
								<td class="right aligned">{{ calculator.disponible }}</td>
							</tr>
						</tbody>
						<tfoot class="full-width center aligned">
							<tr>
								<th :class="budget.sign" colspan="2">
									<i class="ui user large icon"></i> Delegado <i :class="budget.sign == 'add' ? 'plus' : 'minus'" class="ui large icon"></i>
								</th>
							</tr>
						</tfoot>
					</table>
					<div v-show="budget.error" class="ui negative message">
						<p>{{ budget.errorMessage }}</p>
					</div>
				</div>
				<div class="actions">
					<div class="ui small button" @click.prevent="hideBudgetManager">Cancelar</div>
					<div class="ui small green loading button" v-if="budget.savingData">Aceptar</div>
					<div class="ui small green button" v-else :class="{ 'disabled': budget.error }" @click.prevent="budgetTransation">Aceptar</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* global  $ */
// Necesaria para jQuery

import { db } from '../firebaseInit.js'
import { ref, onValue, query, orderByChild, onChildAdded, equalTo, update } from "firebase/database"
import OrdersStatusWidget from '../components/OrdersStatusWidget.vue'

export default {
	name: 'OrdersView',
	components: {
		OrdersStatusWidget
	},
	data: function () {
		return {
			pedidos: [],
			user: {},
			asignado: null,
			nroPedidos: null,
			consumido: null,
			budget: {
				sign: null,
				amount: null,
				error: false,
				errorMessage: null,
				savingData: false,
			},
			totalAsignado: 0,
		}
	},
	watch: {
		'budget.amount': function (val) {
			// console.log('new: %s, old: %s', val, oldVal)
			this.budget.error = false
			var maxBudget = this.round(this.budget.sign === 'add' ? this.$root.currentBiblioteca.contratado - this.totalAsignado : this.asignado - this.consumido, 2)
			//console.log(maxBudget)
			var that = this
			if (val < 0)
				this.$nextTick(function () {
					that.budget.error = true
					that.budget.errorMessage = 'El importe no puede ser menor de 0,00'
				})
			if (val > maxBudget)
				this.$nextTick(function () {
					that.budget.error = true
					that.budget.errorMessage = 'El importe no puede ser mayor de ' + maxBudget
				})
		},
	},
	computed: {
		isAdmin: function () {
			return 'admin' == this.$root.yo.rol || 'axon_admin' == this.$root.yo.rol
		},
		calculator: function () {
			var sign = this.budget.sign === 'add' ? 1 : -1
			return {
				dotacion: this.$root.currentBiblioteca.contratado,
				repartido: this.round(this.totalAsignado + this.budget.amount * sign, 2),
				libre: this.round(this.$root.currentBiblioteca.contratado - this.totalAsignado - this.budget.amount * sign, 2),
				presupuesto: this.round(this.asignado + this.budget.amount * sign, 2),
				disponible: this.round(this.asignado - this.consumido + this.budget.amount * sign, 2),
			}
		},
	},
	created: function () {
		var that = this
		this.getUser(function () {
			that.getStats()
			that.sumAsignado()
			that.getOrders()
		})
	},
	methods: {
		getUser: function (next) {
			var that = this
			if (this.$root.reportSelectedUser) {
				// viene de haber pulsado un usuario en el informe
				onValue(ref(db, `usuarios/${this.$root.reportSelectedUser}`), snap => {
					that.user = snap.val()
					that.user.uid = snap.key
					// console.log('getUser')
					next()
				})
			} else {
				// los pedidos son los del propio usuario logeado
				this.user = this.$root.yo
				next()
			}
		},
		getStats: function () {
			var dataLib = this.user.bibliotecas[this.$root.currentBiblioteca.id]
			this.asignado = dataLib.asignado
			this.nroPedidos = dataLib.pedidos
			this.consumido = dataLib.consumido
		},
		getOrders: function () {
			// obtiene los pedidos del usuario
			this.pedidos.length = 0
			const dataRef = query(ref(db, 'pedidos'), orderByChild('pedido_por/uid'), equalTo(this.user.uid))
			const that = this
			onChildAdded(dataRef, function (snap) {
				var pedido = snap.val()
				if (pedido.biblioteca === that.$root.currentBiblioteca.id) {
					pedido.numero = snap.key
					that.pedidos.push(pedido)
				}
			})
		},
		lastStatus: function (pedido) {
			// no se usa
			var history = pedido.historia
			if (history) {
				var keys = Object.keys(history)
				var key = keys[keys.length - 1]
				var status = history[key].estado
				return status
			} else return 'nuevo'
		},
		sumAsignado: function () {
			this.totalAsignado = 0
			const currentBib = this.$root.currentBiblioteca.id
			const dataRef = query(ref(db, 'usuarios'), orderByChild('bibliotecas/' + currentBib))
			var that = this
			onChildAdded(dataRef, function (snap) {
				let user = snap.val()
				if (user.rol == 'delegado') {
					that.totalAsignado += user.bibliotecas[currentBib].asignado
				}
			})

		},
		// checkBudgetInput: function (event) {
		// 	//console.log(event.target.tagName, event.target.checkValidity())
		// 	//if (!event.target.checkValidity()) event.preventDefault()
		// 	// preventDefault() no funciona porque el evento input no es cancelable
		// },
		showBudgetManager: function (sign) {
			this.budget.sign = sign
			$('.budget-manager.ui.modal').modal('setting', 'autofocus', true).modal('setting', 'closable', true).modal('setting', 'detachable', false).modal('setting', 'allowMultiple', false).modal('show')
			$('.budget-manager input').select()
		},
		hideBudgetManager: function () {
			$('.budget-manager.ui.modal').modal('hide')
			this.budget.sign = null
			this.budget.amount = null
		},
		budgetTransation: function () {
			this.budget.savingData = true
			var that = this
			this.$nextTick(() => {
				// no se si es necesario nextTick :-|
				if (that.budget.sign === 'add') {
					// TO DO: volver a chequear que los fondos libres no son menores que el amount
				}
				if (that.budget.sign === 'minus') {
					// TO DO: volver a chequear que el saldo del delegado es suficiente
				}
				const updates = {
					['usuarios/' + that.user.uid + '/bibliotecas/' + that.$root.currentBiblioteca.id + '/asignado']: that.calculator.presupuesto,
				}
				update(ref(db), updates)
					.then(() => {
						that.budget.savingData = false
						that.hideBudgetManager()
					})
					.catch(() => {
						that.budget.error = true
						that.budget.errorMessage = 'No se ha podido cambiar el presupuesto. \nPor favor, refresque el navegador y vuelva a intentarlo.'
					})
			})
		},
		round: function (value, decimals) {
			return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
		},
	},
}
</script>

<style scoped>
.orders-list {
	width: 100%;
	max-width: 60em;
	margin: 1em auto;
}

.title {
	font-size: 3.6em;
	line-height: 1em;
	color: hsla(0, 0%, 45%, 1);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding-top: 0.5rem;
}


.header {
	text-align: center;
	margin: 1em 0 1.5em;
}

.header>div {
	display: inline-block;
}

.header>.name {
	font-size: 1.3em;
	font-style: italic;
	font-weight: 300;
	color: Chocolate;
	margin-right: 0.5em;
}

.stats .ui.grey.button.small {
	padding: 11px;
}

.stats .ui.grey.button.small i.icon {
	font-size: 1.2em;
	margin: 0;
	cursor: pointer;
}

.stats .ui.grey.button.small .minus.icon:hover {
	color: hsl(10, 100%, 60%) !important;
}

.stats .ui.grey.button.small .add.icon:hover {
	color: hsl(127, 80%, 55%) !important;
}

.ui.button {
	margin-right: 0.5rem;
}

.ui.button,
a.ui.label {
	cursor: default;
}

.ui.grey.button {
	background-color: hsl(0, 0%, 30%);
}

.ui.green.button {
	background-color: hsl(120, 56%, 28%);
}

.ui.purple.button {
	background-color: hsl(300, 82%, 27%);
}

.ui.violet.button {
	background-color: hsl(230, 42%, 32%);
}

.ui.basic.grey.label {
	color: hsl(0, 0%, 30%) !important;
	border-color: hsl(0, 0%, 30%) !important;
}

.ui.basic.purple.label {
	color: hsl(300, 100%, 25%) !important;
	border-color: hsl(300, 100%, 25%) !important;
}

.ui.basic.violet.label {
	color: hsl(242, 34%, 48%) !important;
	border-color: hsl(242, 34%, 48%) !important;
}

/* .orders-list .ui.basic.table {
	background-color: hsl(0, 0%, 97%);
	max-width: 18em;
	margin: 0 0.5em 0 1.5em;
} */

/* .orders-list .ui.basic.table tr:first-of-type {
	color: hsl(177, 100%, 30%);
	font-style: italic;
} */

/* .orders-list .ui.basic.table td:nth-of-type(2) {
	text-align: right;
} */


/* Order */
.order {
	background-color: hsla(0, 0%, 96%, 1);
	margin: 1em 0.5em 0.5em 1.5em;
	padding: 0.6em 1em 0.6em;
	border: 1px solid hsl(0, 0%, 73%);
	border-radius: 5px;
}

.order.anulado {
	background-color: hsla(0, 0%, 82%, 1);
}

.order>ul {
	/*Importe y nro. pedido*/
	float: right;
	font-weight: 500;
	margin: 0;
	padding: 0;
}

.order>ul>li {
	color: hsl(177, 100%, 30%);
	display: inline;
	list-style: none;
	padding-left: 1em;
}

.order>ul>li>label {
	color: #888;
	font-size: 0.8em;
}

.order>ul>li:last-child {
	color: DarkOrange;
}

.order>div:nth-of-type(1) {
	/*Titulo*/
	padding-top: 0.3em;
	font-weight: 500;
	font-size: 0.87em;
	color: hsl(0, 0%, 20%);
}

.order>div:nth-of-type(2) {
	/*Referencia*/
	font-style: italic;
	color: hsl(0, 0%, 10%);
}

.order .ui.grey.ribbon.label:after {
	border-right-color: hsl(0, 0%, 33%);
}



/* modal presupuesto */
.budget-manager.ui.small.modal {
	width: 460px;
	margin-left: -230px;
}

.budget-manager.ui.small.modal .ui.table {
	font-weight: 500;
	color: hsl(0, 0%, 20%);
}

.budget-manager.ui.small.modal th {
	padding-top: 0.6em;
	padding-bottom: 0.2em;
	color: #000;
	background-color: rgba(0, 0, 0, 0.06);
}

.budget-manager.ui.small.modal thead th.minus {
	color: hsl(0, 70%, 43%);
}

.budget-manager.ui.small.modal tfoot th.add {
	color: hsl(134, 70%, 43%);
}

.budget-manager.ui.small.modal th i {
	line-height: 0.8;
}

.budget-manager.ui.small.modal tr td:first-child {
	font-weight: 500;
	color: hsl(0, 0%, 20%);
}

.budget-manager.ui.small.modal tr.add td {
	background-color: #fff;
	color: hsl(134, 70%, 43%);
}

.budget-manager.ui.small.modal tr.minus td {
	background-color: #fff;
	color: hsl(0, 70%, 43%);
}

.budget-manager.ui.small.modal input {
	text-align: right;
	font-family: 'Fira Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 700;
	padding-right: 0.6em !important;
}

.budget-manager.ui.small.modal .ui.input input:invalid {
	/* no va */
	border-color: red !important;
}

.budget-manager.ui.small.modal tr.add input {
	color: hsl(134, 70%, 43%);
}

.budget-manager.ui.small.modal tr.minus input {
	color: hsl(0, 70%, 43%);
}

.budget-manager.ui.small.modal tr.add input::selection {
	color: #fff;
	background: hsl(134, 70%, 43%);
}

.budget-manager.ui.small.modal tr.minus input::selection {
	color: #fff;
	background: hsl(0, 70%, 43%);
}
</style>