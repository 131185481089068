import Vue from 'vue'
import App from './App.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db } from './firebaseInit.js'
import { ref, onChildChanged, onValue } from 'firebase/database'

Vue.config.productionTip = false
Vue.config.debug = true
Vue.config.devtools = true

Vue.filter('formatEU', function (value) {
	// var formatterEUR = new Intl.NumberFormat('es-ES', { useGrouping: 'always', minimumFractionDigits: 2 })
	var formatterEUR = new Intl.NumberFormat('es-ES', { useGrouping: 'always' })
	return formatterEUR.format(value)
})

Vue.filter('round', function (value) {
	return Math.round(value)
})

Vue.filter('accountingES', {
	read: function () {
		// return accounting.formatNumber(value, 2, '.', ',')
	},
	write: function () {
		// var unformated = accounting.unformat(value, ',')
		// var formated = accounting.toFixed(unformated, 2) // por si tiene mas de 2 decimales.
		// return accounting.unformat(formated)
	},
})

Vue.filter('fechaCorta', function (timestamp) {
	const theTime = new Date(0)
	theTime.setTime(timestamp)
	return new Intl.DateTimeFormat('es').format(theTime)
})

Vue.filter('timestampPedidos', function (timestamp) {
	const date = new Date(0)
	date.setTime(timestamp)
	const formatter = new Intl.DateTimeFormat('es', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})
	return formatter.format(date)
})

Vue.filter('longTimestamp', function (timestamp) {
	const date = new Date(0)
	date.setTime(timestamp)
	const formatter = new Intl.DateTimeFormat('es', {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true,
	})
	return formatter.format(date)
})

new Vue({
	render: h => h(App),
	data: {
		yo: null,
		isAuthenticated: false,
		currentView: '', // por defecto
		currentBiblioteca: {},
		isPublic: false,
		reportSelectedUser: null,
		environment: null,
		publicPaths: {},
		publicSubject: null,
	},
	computed: {
		idUserBibliotecaActual: function () {
			if (this.isAuthenticated) return this.yo.preferencias.biblioteca_actual
			if (this.publicSubject) return this.publicSubject
			return null
		},
		userBibliotecaActual: function () {
			if (!this.yo) return null
			return this.yo.bibliotecas[this.idUserBibliotecaActual]
		},
		isPublicZone: function () {
			var zone = window.location.pathname
			if (zone == '/medico') return true
			else return false
		},
	},
	created: function () {
		this.setDatabaseEventListeners()
	},
	mounted: function () {},
	methods: {
		setDatabaseEventListeners: function () {
			// Cada vez que cambia el número de versión (nuevo deploy)
			onChildChanged(ref(db, 'version'), function () {
				window.location.reload(true)
			})

			// Cuando se cambia el environment
			onValue(ref(db, 'environment'), snap => {
				this.environment = snap.val()
			})

			// Lee el mapa de path -> materia
			onValue(ref(db, 'public_paths'), snap => {
				this.publicPaths = snap.val()
				const publicPath = window.location.pathname.replace('/', '')
				this.publicSubject = this.publicPaths[publicPath]
			})

			// Cada vez que cambia el estado de la Authenticación
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
				if (user) {
					this.isAuthenticated = true
					if (!this.yo) this.readUserData(user.uid)
				} else {
					// console.log('User is signed out')
					this.isAuthenticated = false
					this.route('LoginView')
					this.clearUserData()
				}
			})
		},
		route: function (view) {
			// console.log('view', view)
			this.currentView = null
			if (!this.isAuthenticated && this.isPublicZone) return (this.currentView = 'BooksView')
			if (!this.isAuthenticated) return (this.currentView = 'LoginView') // no está autenticado
			if (!this.yo.perfil_completo) return (this.currentView = 'ProfileView') // no permite ver nada hasta que el perfil esté completo
			this.currentView = view
			// console.log('currentView', this.currentView)
		},
		readUserData: function (uid) {
			// Se usa onValue para que se reflejen los cambios del usuario cuando este se da de alta o cambia algun dato en su perfil.
			const dataRef = ref(db, 'usuarios/' + uid)
			onValue(dataRef, snap => {
				this.yo = snap.val()
				this.yo.uid = snap.key
				this.route('BooksView')
				// this.getAllowedBibliotecas()
				this.readBibliotecaData()
			})
		},
		clearUserData: function () {
			this.yo = null
			this.currentBiblioteca = {}
			// this.allowedBibliotecas = []
			// this.showCursarPedido = false
			this.reportSelectedUser = null
			// this.orderDetail = null
			// this.environment = null
		},
		setPublicSubject: function () {
			const publicPath = window.location.pathname.replace('/', '')
			return this.publicPaths[publicPath]
		},
		readBibliotecaData: function () {
			// añade datos a la biblioteca actual
			const dataRef = ref(db, 'bibliotecas/' + this.idUserBibliotecaActual)
			onValue(
				dataRef,
				snap => {
					this.currentBiblioteca.id = this.idUserBibliotecaActual
					this.currentBiblioteca.contratado = snap.val().contratado
					this.currentBiblioteca.titulo = snap.val().titulo
				},
				{
					onlyOnce: true,
				}
			)
		},
	},
}).$mount('#app')
