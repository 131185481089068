<template>
	<div class="counters">
		<div class="ui labeled button small" tabindex="0">
			<div class="ui grey button small"> Presupuesto </div>
			<a class="ui basic left pointing grey label small"> {{ asignado | formatEU }} € </a>
		</div>
		<div class="ui labeled button small" tabindex="0">
			<div class="ui purple button small"> {{ pedidos | formatEU }} Pedidos </div>
			<a class="ui basic left pointing purple label small"> {{ consumido | formatEU }} € </a>
		</div>
		<div class="ui labeled button small" tabindex="0">
			<div class="ui violet button small"> Disponible </div>
			<a class="ui basic left pointing violet label small"> {{ asignado - consumido | formatEU }} € </a>
		</div>
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, query, equalTo, orderByChild, onChildAdded } from "firebase/database"

export default {
	name: 'ReportRegionCounters',
	props: ['jefeUid'],
	data: function () {
		return {
			asignado: 0,
			pedidos: 0,
			consumido: 0,
		}
	},
	created: function () {
		this.getStats()
	},
	methods: {
		getStats: function () {
			var currentBib = this.$root.currentBiblioteca.id
			const that = this

			if (!this.jefeUid) { // Si no se recibe el uid de un jefe obtiene los contadores de toda la biblioteca
				// Obtiene todos los delegados que son miembros de la biblioteca actual
				const dataRef = query(ref(db, 'usuarios'), orderByChild('bibliotecas/' + currentBib))
				onChildAdded(dataRef, function (snap) {
					var user = snap.val()
					if (user.rol == 'delegado') {
						that.asignado += user.bibliotecas[currentBib].asignado
						that.pedidos += user.bibliotecas[currentBib].pedidos
						that.consumido += user.bibliotecas[currentBib].consumido
					}
				})
			} else {
				// Obtiene sólo los delegados cuyo superior uid es el recibido en las propiedades
				const dataRef = query(ref(db, 'usuarios'), orderByChild('bibliotecas/' + currentBib + '/superior'), equalTo(this.jefeUid))
				onChildAdded(dataRef, function (snap) {
					const countersDelegado = snap.val().bibliotecas[currentBib]
					that.asignado += countersDelegado.asignado
					that.pedidos += countersDelegado.pedidos
					that.consumido += countersDelegado.consumido
				})

			}
		},
	}
}
</script>

<style scoped>
.ui.button {
	margin-right: 0.5rem;
}

.ui.button,
a.ui.label {
	cursor: default;
}

.ui.grey.button {
	background-color: hsl(0, 0%, 30%);
}

.ui.green.button {
	background-color: hsl(120, 56%, 28%);
}

.ui.purple.button {
	background-color: hsl(300, 82%, 27%);
}

.ui.violet.button {
	background-color: hsl(230, 42%, 32%);
}

.ui.basic.grey.label {
	color: hsl(0, 0%, 30%) !important;
	border-color: hsl(0, 0%, 30%) !important;
}

.ui.basic.purple.label {
	color: hsl(300, 100%, 25%) !important;
	border-color: hsl(300, 100%, 25%) !important;
}

.ui.basic.violet.label {
	color: hsl(242, 34%, 48%) !important;
	border-color: hsl(242, 34%, 48%) !important;
}
</style>