<template>
	<header v-cloak v-if="yo && !isPublicZone">
		<div class="nav">
			<i class="university icon" @click="route('BooksView')"><span></span></i>
			<div v-if="multiplesBibliotecas" class="ui inline dropdown">
				<i class="dropdown icon"></i>
				<div class="menu">
					<div class="div">Seleccione Materia</div>
					<!-- <div v-for="bib in allowedBibliotecas" class="item" data-text="{{bib.id}}" @click="setPreferedBiblioteca(bib.id)">{{bib.titulo}}</div> -->
				</div>
			</div>
			<i class="ui bar chart icon" v-show="showReport" @click="route('ReportView')"><span></span></i>
			<i class="list layout icon" v-show="showOrders" @click="route('OrdersView')"><span></span></i>
			<i class="list layout icon admin" v-show="isAxonUser" @click="route('AdminListView')"><span></span></i>
			<a v-if="yo.environment == 'staging'" class="ui green basic label">Staging</a>
			<div class="me-name" v-text="yo ? (yo.nombre) : ''"></div>
			<i class="setting icon" @click="route('ProfileView')"></i>
			<i class="info icon" @click="route('InfoView')"></i>
			<i class="sign out icon" @click="logout"></i>
		</div>
	</header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth"
export default {
	name: 'NavigationBar',
	computed: {
		yo: function () {
			return this.$root.yo
		},
		showReport: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'axon_operador' || this.yo.rol == 'admin' || this.yo.rol == 'jefe')
		},
		showOrders: function () {
			return this.yo && this.yo.rol == 'delegado'
		},
		multiplesBibliotecas: function () {
			return this.yo && Object.keys(this.yo.bibliotecas).length > 1
		},
		isAxonUser: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'axon_operador')
		},
		isAdmin: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'admin')
		},
		isPublicZone: function () {
			var zone = window.location.pathname
			if (zone == '/nervioso' || zone == '/respiratorio' || zone == '/medicina') return true
			else return false
		},
	},
	methods: {
		logout: function () {
			if (this.yo) {
				// this.route('login')
				// this.clearUserData()

				const auth = getAuth();
				signOut(auth).then(() => {
					// Sign-out successful.
					this.$root.route('LoginView')
				}).catch((error) => {
					console.log(error)
				});

			}
		},
		route: function (view) {
			this.$root.route(view)
		}
	}
}
</script>

<style scoped>
/* header {
	position: sticky;
	top: 0;
	z-index: 3; 
	width: 100%;
} */

.nav {
	display: flex;
	height: 50px;
	padding: 0 5%;
	color: #ccc;
	background-color: black;
	white-space: nowrap;
	align-items: center;
}

header>.nav>i {
	cursor: pointer;
	font-size: 1.7em;
	padding: 0.3em 0.4em;
	margin: 0;
	width: inherit;
	height: inherit;
}

header>.nav>i:hover {
	color: #fff;
}

header>.nav>i>span {
	font: normal normal 400 0.6em 'Fira Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

header .ui.inline.dropdown {
	color: chocolate;
}

header .ui.inline.dropdown:hover {
	color: #fff;
}

header .ui.inline.dropdown .dropdown.icon {
	font-size: 1.5em;
	margin: .65em .7em .29em .3em;
}

header .university.icon {
	padding-right: 0.1em
}

header>.nav>.university.icon span:after {
	content: " Biblioteca";
}

header .ui.bar.chart span:after {
	content: " Informe";
}

[v-cloak] {
	display: none;
}

.me-name {
	font-size: 0.95em;
	color: Chocolate;
	margin: 0 0.7em -4px auto;
}


header .list.layout.icon span:after {
	content: " Pedidos";
}

@media screen and (max-width: 1536px) {
	.nav {
		padding: 0 4%;
	}
}

@media screen and (max-width: 1280px) {
	.nav {
		padding: 0 3%;
	}
}

@media screen and (max-width: 1024px) {
	.nav {
		padding: 0 2%;
	}
}

/* @media screen and (max-width: 768px) {
	.nav {
		padding: 0 1.5%;
	}
} */

@media screen and (max-width: 640px) {
	.nav {
		padding: 0 1%;
	}
}

@media screen and (max-width: 640px) {

	header>.nav>.university.icon span:after {
		content: "";
	}

	header .ui.bar.chart span:after {
		content: "";
	}

	header .list.layout.icon span:after {
		content: "";
	}
}
</style>