<template>
	<div class="book" :class="{ 'dimmed': showFicha }">
		<!-- <img class="portada" :src="url" v-show="!showFicha" @click="toggleDisplay" @load="imgLoaded = true" /> -->
		<img class="portada" :src="url" @click="toggleDisplay" @load="imgLoaded = true" />
		<div class="badge" v-show="imgLoaded" @click="toggleDisplay">{{ book.numero }}</div>
		<div class="overlay" v-if="book.agotado && imgLoaded" @click="toggleDisplay">AGOTADO</div>
		<img class="ebook" v-if="book.ebook && imgLoaded" @click="toggleDisplay" src="img/sello_ebook.png" />
		<div class="container-ficha" v-show="showFicha" @click="toggleDisplay">
			<div class="ficha">
				<div class="cabecera">
					<div class="titulo">{{ book.titulo }}</div>
					<div class="autores">{{ book.autores }}</div>
				</div>
				<div class="datos">
					<div style="position:relative">
						<img class="portada2" :src="url" />
						<img class="ebook-ficha" v-if="book.ebook && imgLoaded" src="img/sello_ebook.png" />
					</div>
					<table class="tablaDatos">
						<tbody>
							<tr>
								<td>ISBN</td>
								<td>{{ book.isbn }}</td>
							</tr>
							<tr>
								<td>Publicado</td>
								<td>{{ book.publicado }}</td>
							</tr>
							<tr>
								<td>Edición</td>
								<td>{{ book.edicion }}</td>
							</tr>
							<tr>
								<td>Idioma</td>
								<td>{{ book.idioma }}</td>
							</tr>
							<tr>
								<td>Páginas</td>
								<td>{{ book.paginas | formatEU }}</td>
							</tr>
							<tr>
								<td>Peso</td>
								<td>{{ book.peso }}</td>
							</tr>
							<tr>
								<td>Dimensiones</td>
								<td>{{ book.ancho }} x {{ book.alto }} x {{ book.grueso }} cms.</td>
							</tr>
							<tr>
								<td>Editorial</td>
								<td>{{ book.editorial }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="pedir">
					<button v-show="$root.isAuthenticated" class="ui mini primary button right floated" :class="{ 'disabled': book.agotado }" id="show-cursar-pedido" @click.stop="renderOrderForm">Pedir</button>
				</div>
				<div class="content">
					<h2 v-if="book.indice_materias">Índice de Materias</h2>
					<div v-if="book.indice_materias" v-html="book.indice_materias"></div>
					<h2 v-if="book.descripcion">Descripción</h2>
					<div v-if="book.descripcion" v-html="book.descripcion"></div>
				</div>
			</div>
		</div>
		<BookCardForm v-if="orderFormExist" :book="book" v-on:order_placed="orderPlaced"></BookCardForm>
	</div>
</template>

<script>
import BookCardForm from './BookCardForm.vue'

export default {
	name: 'BookCard',
	components: {
		BookCardForm
	},
	// props: ['book', 'num'],
	props: ['book'],
	computed: {
		url: function () {
			return '//' + this.book.url_imagen
		},
	},
	data: function () {
		return {
			showFicha: false,
			orderFormExist: false,
			imgLoaded: false,
		}
	},
	methods: {
		toggleDisplay: function () {
			if (this.$parent.selectedBook !== this) {
				this.$parent.selectedBook.showFicha = false
			}
			this.showFicha = !this.showFicha
			this.$parent.selectedBook = this
		},
		renderOrderForm: function () {
			this.orderFormExist = true
		},
		orderPlaced: function () {
			this.orderFormExist = false
			this.$root.route('OrdersView')
		}
	},
}
</script>

<style scoped>
.book {
	position: relative;
	width: 240px;
	height: 340px;
	/* margin: 0.4em 0; */
	cursor: pointer;
}

.book .portada {
	width: 100%;
	height: 100%;
	vertical-align: bottom;
}

.book.dimmed {
	/* background-color: darkred; */
	border: 3px solid white;
}

.book.dimmed>.portada,
.book.dimmed>.ebook {
	opacity: 0.5;
}

.book .container-ficha {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 68px 8px 8px;
}

.book .ficha {
	position: relative;
	margin: auto;
	width: 100%;
	max-width: 752px;
	height: 100%;
	background-color: hsl(104, 20%, 90%);
	overflow: scroll;
	box-shadow: 0 0 16px 6px #262626;
}

.book .ficha .cabecera {
	padding: 2em 1.5em 0 2.5em;
}

.book .ficha .cabecera .titulo {
	font-weight: 400;
	font-size: 1.3em;
	color: chocolate;
}

.book .ficha .cabecera .autores {
	font-weight: 400;
	font-size: 1.2em;
	font-style: italic;
	color: hsl(0, 0%, 10%);
}

.book .ficha .datos {
	padding: 1.5em 1.5em 0.5em 2.5em;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.book .ficha .datos .portada2 {
	width: 240px;
	height: 340px;
}

table.tablaDatos {
	text-align: left;
}

table.tablaDatos td {
	border: 1px solid hsla(102, 20%, 60%, 0.7);
	padding: 0.5em 1em;
}

table.tablaDatos tbody td {
	font-size: 1em;
}

table.tablaDatos td:nth-child(odd) {
	font-size: 0.9em;
	color: hsla(102, 20%, 15%, 0.8);
}

table.tablaDatos td:nth-child(even) {
	background-color: whitesmoke;
}

.book .ficha .pedir {
	padding: 0 2.5em 1em;
}

.book .ficha .content {
	padding: 2em 1.5em 1.5em 2.5em;
	background-color: whitesmoke;
	font-size: 1.1em;
	font-weight: 400;
	font-family: 'times new roman';
}

.book .ficha .content h2 {
	font-size: 1.1em;
	color: chocolate;
}

.book .badge {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	z-index: 1;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 1.7em;
	height: 1.7em;
	border-radius: 6px;
	color: #fff;
	background-color: hsla(28, 100%, 44%, 0.69);
}

.book .overlay {
	position: absolute;
	transform: rotate(-45deg);
	text-shadow: -1px 0 #000000, 0 1px #000000, 1px 0 #000000, 0 -1px #000000;
	color: hsl(28, 100%, 50%);
	font-size: 3.6em;
	font-weight: 700;
	top: 40%;
	width: 100%;
	text-align: center;
}

.book .ebook {
	position: absolute;
	width: 64px;
	height: 64px;
	bottom: 5px;
	left: 88px;
}

.book .ebook-ficha {
	position: absolute;
	width: 64px;
	height: 64px;
	top: 268px;
	left: 88px;
	border: inherit;
	margin: inherit;
}
</style>